/*
 * Copyright 2018- The Pixie Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import * as React from 'react';

export const TabSwitcherProviderContext = React.createContext(
  {
    sharedChoice: null,
    setSharedChoice: null,
  },
);
export default function TabSwitcherProvider({ children }) {
  const [sharedChoice, setSharedChoice] = React.useState(null);

  return (
    <TabSwitcherProviderContext.Provider value={{ sharedChoice, setSharedChoice }}>
      {children}
    </TabSwitcherProviderContext.Provider>
  );
}
