// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-datatable-docs-index-tsx": () => import("./../src/templates/datatableDocsIndex.tsx" /* webpackChunkName: "component---src-templates-datatable-docs-index-tsx" */),
  "component---src-templates-datatable-docs-tsx": () => import("./../src/templates/datatableDocs.tsx" /* webpackChunkName: "component---src-templates-datatable-docs-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-mutation-docs-tsx": () => import("./../src/templates/mutationDocs.tsx" /* webpackChunkName: "component---src-templates-mutation-docs-tsx" */),
  "component---src-templates-pxl-object-index-tsx": () => import("./../src/templates/pxlObjectIndex.tsx" /* webpackChunkName: "component---src-templates-pxl-object-index-tsx" */),
  "component---src-templates-py-docs-index-tsx": () => import("./../src/templates/pyDocsIndex.tsx" /* webpackChunkName: "component---src-templates-py-docs-index-tsx" */),
  "component---src-templates-udf-docs-index-tsx": () => import("./../src/templates/udfDocsIndex.tsx" /* webpackChunkName: "component---src-templates-udf-docs-index-tsx" */),
  "component---src-templates-udf-docs-tsx": () => import("./../src/templates/udfDocs.tsx" /* webpackChunkName: "component---src-templates-udf-docs-tsx" */)
}

