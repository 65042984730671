module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images"},{"resolve":"/opt/build/repo/src/plugins/gatsby-plugin-code-tabs.ts"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"showCaptions":true,"markdownCaptions":true}},{"resolve":"gatsby-remark-copy-linked-files"}],"remarkPlugins":[null],"extensions":[".mdx",".md"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.px.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":null,"head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"mkQm9L1JycuiXWE7kx1FdDBQMFhJ5fPP","devKey":"OaiiRl4gZWFGMHVFRgi01yKfnsqitZ7g","trackPage":true},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
